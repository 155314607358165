import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, } from 'react';
import { isZAPIError, ErrorCode as ZAPIErrorCodes, } from '@zattoo/zapi/lib/helpers/error';
import { Teasable } from '@zattoo/zapi/lib/editorial/enums';
import { getLanguageNames } from '@zattoo/zapi';
import { ErrorCode, EventKey, isPlaybackError, MediaType, PlaybackState, } from '@zattoo/playback-sdk';
import { styles } from './styles';
import { createPlayer, PlayerView, } from '../platforms';
import { UTVPlayerEvents, UTVPlayerStreamType, UTVPlayerErrorType, } from './interfaces';
import { initialProgramInfo, playerInitialState, } from './states';
import { getISOLanguageName, setMediaTrackById, updateProgram, } from './utils';
const DEBUG_MODE = false;
const debugLogger = (error) => {
    if (DEBUG_MODE) {
        console.error(error);
    }
};
const eventToPlayerEvent = (event) => {
    switch (event.type) {
        case EventKey.MEDIA_CHANGED:
        case EventKey.CURRENT_POSITION_CHANGED:
        case EventKey.PLAYER_STATE_CHANGED:
        case EventKey.PLAYBACK_STATE_CHANGED:
            return UTVPlayerEvents.STATE_UPDATED;
        case EventKey.AVAILABLE_AUDIO_TRACKS_CHANGED:
        case EventKey.AVAILABLE_SUBTITLES_TRACKS_CHANGED:
        case EventKey.SELECTED_AUDIO_TRACK_CHANGED:
        case EventKey.SELECTED_SUBTITLES_TRACK_CHANGED:
            return UTVPlayerEvents.TRACK_UPDATED;
        // eslint-disable-next-line no-fallthrough
        default:
            console.log('Unknown event:', event);
            return UTVPlayerEvents.UNKNOWN;
    }
};
const OFF_SUBTITLE_TRACK = {
    id: 'off',
    locale: 'off',
    label: 'Off',
};
export const UTVPlayerView = forwardRef(({ onEvent, host, publicId, appVersion, appId, stepForwardDuration, stepBackwardDuration, style = styles.player, }, ref) => {
    const playerInstanceRef = useRef(null);
    const playerStateRef = useRef(playerInitialState);
    const programInfoRef = useRef(initialProgramInfo);
    const languageNamesRef = useRef(undefined);
    const updateUTVPlayerState = useCallback((type, newState) => {
        const nextState = {
            ...playerStateRef.current,
            ...newState,
        };
        playerStateRef.current = nextState;
        onEvent?.({
            event: type,
            state: nextState,
        });
    }, [onEvent]);
    const triggerError = useCallback((type, data) => {
        const nextState = {
            ...playerStateRef.current,
            stream: playerStateRef.current.stream,
        };
        playerStateRef.current = nextState;
        onEvent?.({
            event: UTVPlayerEvents.ERROR,
            state: nextState,
            error: {
                errorType: type,
                errorData: data,
            },
        });
    }, [onEvent]);
    useEffect(() => {
        playerInstanceRef.current?.destroy();
        programInfoRef.current = initialProgramInfo;
        playerInstanceRef.current = createPlayer({
            host,
            publicId,
            appVersion,
            appId,
            stepBackwardDuration,
            stepForwardDuration,
        });
        updateUTVPlayerState(UTVPlayerEvents.INITIALIZED);
        getLanguageNames().then((response) => {
            languageNamesRef.current = response;
        }).catch((error) => {
            triggerError(UTVPlayerErrorType.UNKNOWN, error);
        });
        const updateCurrentPosition = (event) => {
            let currentMs = (event.position * 1000) + (playerStateRef.current.streamStartMs ?? 0);
            if (programInfoRef.current.streamType === UTVPlayerStreamType.LIVE) {
                currentMs -= programInfoRef.current.programStartEpochMs;
            }
            updateUTVPlayerState(eventToPlayerEvent(event), { currentMs });
        };
        const updatePlaybackState = (event) => {
            switch (event.state) {
                case PlaybackState.STOPPED:
                    updateUTVPlayerState(UTVPlayerEvents.STOPPED, {
                        buffering: false,
                        canPlay: false,
                        canPause: false,
                    });
                    break;
                case PlaybackState.PLAYING:
                    updateUTVPlayerState(UTVPlayerEvents.PLAYING, {
                        buffering: false,
                        canPlay: false,
                    });
                    break;
                case PlaybackState.PAUSED:
                    updateUTVPlayerState(UTVPlayerEvents.PAUSED, {
                        canPlay: true,
                        canPause: true,
                    });
                    break;
                case PlaybackState.BUFFERING:
                    updateUTVPlayerState(UTVPlayerEvents.STATE_UPDATED, {
                        buffering: true,
                    });
                    break;
                default:
                    break;
            }
        };
        const updatePlayerState = (event) => {
            const newState = {
                canForward: event.canSeekForward,
                canBackward: event.canSeekBackward,
                canPause: event.canPause,
                // @todo: Check if there is edge case
                canPlayFromStart: event.canSeekBackward,
            };
            if (event.seekableRange) {
                const endMs = event.seekableRange.end * 1000;
                const endRelativeTimeMs = programInfoRef.current.streamType === UTVPlayerStreamType.LIVE
                    ? endMs - programInfoRef.current.programStartEpochMs
                    : endMs;
                newState.programStartMs = 0;
                newState.programEndMs = programInfoRef.current.programEndEpochMs
                    - programInfoRef.current.programStartEpochMs;
                newState.streamStartMs = 0 - programInfoRef.current.preMs;
                newState.streamEndMs = endRelativeTimeMs - programInfoRef.current.preMs;
            }
            updateUTVPlayerState(eventToPlayerEvent(event), newState);
        };
        const updateAudioTracks = (event) => {
            updateUTVPlayerState(eventToPlayerEvent(event), {
                audioTracks: event.tracks.map((track) => ({
                    ...track,
                    label: getISOLanguageName(track, languageNamesRef.current),
                })),
            });
        };
        const updateSubtitlesTracks = (event) => {
            const mappedSubtitleTracks = event.tracks.map((track) => ({
                ...track,
                label: getISOLanguageName(track, languageNamesRef.current),
            }));
            mappedSubtitleTracks.push(OFF_SUBTITLE_TRACK);
            updateUTVPlayerState(eventToPlayerEvent(event), {
                subtitleTracks: mappedSubtitleTracks,
                currentSubtitleTrack: OFF_SUBTITLE_TRACK,
            });
        };
        const updateSelectedAudioTrack = (event) => {
            if (!event.targetTrack) {
                updateUTVPlayerState(eventToPlayerEvent(event), {
                    currentAudioTrack: undefined,
                });
                return;
            }
            updateUTVPlayerState(eventToPlayerEvent(event), {
                currentAudioTrack: {
                    ...event.targetTrack,
                    label: getISOLanguageName(event.targetTrack, languageNamesRef.current),
                },
            });
        };
        const updateSelectedSubtitleTrack = (event) => {
            if (!event.targetTrack) {
                updateUTVPlayerState(eventToPlayerEvent(event), {
                    currentSubtitleTrack: OFF_SUBTITLE_TRACK,
                });
                return;
            }
            updateUTVPlayerState(eventToPlayerEvent(event), {
                currentSubtitleTrack: {
                    ...event.targetTrack,
                    label: getISOLanguageName(event.targetTrack, languageNamesRef.current),
                },
            });
        };
        const updateMedia = (event) => {
            const { mediaType } = event.media;
            programInfoRef.current.preMs = event.media.prePadding * 1000;
            programInfoRef.current.postMs = event.media.postPadding * 1000;
            const playerDuration = playerInstanceRef.current?.duration ?? 0;
            const durationMs = isFinite(playerDuration) ? playerDuration * 1000 : 0;
            updateUTVPlayerState(eventToPlayerEvent(event), {
                canPlayLive: mediaType === MediaType.REPLAY || mediaType === MediaType.REGISTERED_TIMESHIFT,
                programStartMs: 0,
                programEndMs: programInfoRef.current.programEndEpochMs - programInfoRef.current.programStartEpochMs,
                streamStartMs: 0 - programInfoRef.current.preMs,
                streamEndMs: durationMs - programInfoRef.current.preMs,
            });
        };
        const updateError = (event) => {
            const error = event.error;
            const isZapiPinError = isZAPIError(error) && (error.code === ZAPIErrorCodes.PIN_MISSING ||
                error.code === ZAPIErrorCodes.PIN_INVALID ||
                error.code === ZAPIErrorCodes.PIN_SETUP_REQUIRED ||
                error.code === ZAPIErrorCodes.PIN_LOCKED);
            const isPlaybackPinError = isPlaybackError(error) && (error.code === ErrorCode.PinRequired);
            if (isZapiPinError || isPlaybackPinError) {
                triggerError(UTVPlayerErrorType.PIN_ERROR, {
                    code: error?.code,
                    data: error,
                });
            }
            triggerError(UTVPlayerErrorType.UNKNOWN, {
                error,
            });
        };
        playerInstanceRef.current?.on(EventKey.CURRENT_POSITION_CHANGED, updateCurrentPosition);
        playerInstanceRef.current?.on(EventKey.PLAYBACK_STATE_CHANGED, updatePlaybackState);
        playerInstanceRef.current?.on(EventKey.PLAYER_STATE_CHANGED, updatePlayerState);
        playerInstanceRef.current?.on(EventKey.AVAILABLE_AUDIO_TRACKS_CHANGED, updateAudioTracks);
        playerInstanceRef.current?.on(EventKey.AVAILABLE_SUBTITLES_TRACKS_CHANGED, updateSubtitlesTracks);
        playerInstanceRef.current?.on(EventKey.SELECTED_AUDIO_TRACK_CHANGED, updateSelectedAudioTrack);
        playerInstanceRef.current?.on(EventKey.SELECTED_SUBTITLES_TRACK_CHANGED, updateSelectedSubtitleTrack);
        playerInstanceRef.current?.on(EventKey.MEDIA_CHANGED, updateMedia);
        playerInstanceRef.current?.on(EventKey.PLAYER_ERROR, updateError);
        return () => {
            playerInstanceRef.current?.destroy();
        };
    }, [
        appId, appVersion, host, onEvent, publicId, stepBackwardDuration,
        stepForwardDuration, updateUTVPlayerState, triggerError,
    ]);
    const play = useCallback((options) => {
        // Reset player state
        // @TODO Set initial state after Play internal methods and set initial state inside of listeners
        playerStateRef.current = {
            ...playerStateRef.current,
            stream: playerStateRef.current.stream,
        };
        if (!options) {
            playerInstanceRef.current?.play();
            return;
        }
        programInfoRef.current.programStartEpochMs = options?.programStartEpochMs ?? 0;
        programInfoRef.current.programEndEpochMs = options?.programEndEpochMs ?? 0;
        programInfoRef.current.preMs = 0;
        programInfoRef.current.postMs = 0;
        programInfoRef.current.streamType = options?.streamType;
        updateUTVPlayerState(UTVPlayerEvents.PLAY_REQUESTED, {
            stream: {
                streamType: options.streamType,
                programDescriptor: options.programDescriptor,
            },
        });
        switch (options?.streamType) {
            case UTVPlayerStreamType.VOD_MOVIE: {
                playerInstanceRef.current?.playVod(options.permissionToken, options.teasableId, Teasable.VOD_MOVIE, options).catch(debugLogger);
                break;
            }
            case UTVPlayerStreamType.VOD_EPISODE: {
                playerInstanceRef.current?.playVod(options.permissionToken, options.teasableId, Teasable.VOD_SERIES_EPISODE, options).catch(debugLogger);
                break;
            }
            case UTVPlayerStreamType.LIVE: {
                const { channelId, ...playOptions } = options;
                playerInstanceRef.current?.playLive(channelId, updateProgram(playOptions)).catch(debugLogger);
                break;
            }
            case UTVPlayerStreamType.REPLAY: {
                const { channelId, programId, ...playOptions } = options;
                playerInstanceRef.current?.playProgram(channelId, programId, updateProgram(playOptions)).catch(debugLogger);
                break;
            }
            case UTVPlayerStreamType.RECORDING: {
                const { programId, ...playOptions } = options;
                playerInstanceRef.current?.playRecording(programId, updateProgram(playOptions)).catch(debugLogger);
                break;
            }
            default: {
                playerInstanceRef.current?.play();
                break;
            }
        }
    }, [updateUTVPlayerState]);
    // const playFromStart = useCallback((params) => {
    // }, []);
    // Positive and negative numbers
    const seek = useCallback((numSteps) => {
        if (numSteps > 0) {
            playerInstanceRef.current?.seekForward(numSteps);
        }
        else if (numSteps < 0) {
            playerInstanceRef.current?.seekBackward(Math.abs(numSteps));
        }
    }, []);
    // Seek to position (relative program time ms)
    const seekTo = useCallback((positionMs) => {
        if (positionMs >= 0) {
            const newPositionMs = positionMs - (playerStateRef.current.streamStartMs ?? 0);
            playerInstanceRef.current?.seek(newPositionMs / 1000);
        }
    }, []);
    const setAudioTrack = useCallback((id) => {
        setMediaTrackById(id, playerStateRef.current.audioTracks, playerInstanceRef.current?.setAudioTrack.bind(playerInstanceRef.current));
    }, []);
    const setSubtitleTrack = useCallback((id) => {
        if (id === 'off') {
            playerInstanceRef.current?.setSubtitlesTrack(null);
            return;
        }
        setMediaTrackById(id, playerStateRef.current.subtitleTracks, playerInstanceRef.current?.setSubtitlesTrack.bind(playerInstanceRef.current));
    }, []);
    // const fetchNextPlay = useCallback((params) => {
    // }, []);
    const pause = useCallback(() => {
        playerInstanceRef.current?.pause();
    }, []);
    useImperativeHandle(ref, () => ({
        play,
        pause,
        seek,
        seekTo,
        setAudioTrack,
        setSubtitleTrack,
    }), [play, seek, seekTo, setAudioTrack, setSubtitleTrack, pause]);
    if (!playerInstanceRef.current) {
        return null;
    }
    return (React.createElement(PlayerView, { player: playerInstanceRef.current, style: style }));
});
