import { MediaType } from './stream/enums';
import { ErrorCode } from './errors/enums/error-code';
import { isPlaybackError } from './errors';
import { pickHighestRate } from './stream/utils';
import { Player } from './player/player';
import { AdapterCallbacks } from './adapter/adapter-callbacks';
import { AdapterCallbacksDispatcher } from './adapter/adapter-callbacks-dispatcher';
import { EventKey, PlaybackState, } from './player/enums';
import { WebPlayer } from './player/web-player';
import { ChannelLogger, ConsoleLogger, } from './logger';
import { StreamProtocol, StreamProtection, SubtitlesCodec, } from './capability';
import { Client } from './clients/clients';
import { isApple, isChrome, } from './clients/device-detection';
export const detectCapabilities = () => {
    if (isApple) {
        return {
            default: {
                streamProtocol: StreamProtocol.HLS,
                streamProtection: StreamProtection.FAIRPLAY,
            },
        };
    }
    else if (isChrome) {
        return {
            default: {
                streamProtocol: StreamProtocol.DASH,
                streamProtection: StreamProtection.WIDEVINE,
            },
            optional: [
                {
                    streamProtocol: StreamProtocol.HLS,
                    streamProtection: StreamProtection.NONE,
                },
            ],
        };
    }
    else {
        return {
            default: {
                streamProtocol: StreamProtocol.DASH,
                streamProtection: StreamProtection.WIDEVINE,
            },
        };
    }
};
const createPlayer = (options, 
// We couldn't import bitmovin adapter in this file
// because the build will include Bitmovin codes that requires DOM and breaks Android
// todo: maybe bundler can do magic to avoid this, but not for now
adapter) => {
    const capabilities = detectCapabilities();
    return new WebPlayer(options, adapter, capabilities);
};
export { AdapterCallbacks, AdapterCallbacksDispatcher, ChannelLogger, Client, ConsoleLogger, EventKey, MediaType, PlaybackState, Player, StreamProtection, StreamProtocol, SubtitlesCodec, WebPlayer, ErrorCode, isPlaybackError, createPlayer, pickHighestRate, };
